import React from "react"
import { Link } from "gatsby"

class SplitCard extends React.Component {
  render() {
    const title = this.props.title
    const desc = this.props.desc
    const link = this.props.link
    const btn = this.props.btn
    const image = this.props.image
    const video = this.props.video
    const align = this.props.align
    const style = this.props.style

    // const video = () => (
    //   <div className="video">
    //     <iframe
    //       src={this.props.video}
    //       title=""
    //       allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    //       frameBorder="0"
    //       webkitallowfullscreen="true"
    //       mozallowfullscreen="true"
    //       allowFullScreen
    //     />
    //   </div>
    // )
    return (
      <div>
        <div
          className={
            style === "white"
              ? "uk-flex uk-card uk-card-default uk-card-large uk-border-rounded uk-grid-collapse uk-margin-large-bottom"
              : "uk-flex uk-card uk-card-secondary uk-card-large uk-border-rounded uk-grid-collapse uk-margin-large-bottom"
          }
          uk-grid=""
        >
          {align === "right" ? (
            <div className="uk-card-media-left uk-width-3-5@m uk-height-large uk-cover-container uk-border-rounded-top-left uk-border-rounded-bottom-left">
              {image ? (
                <img src={image} alt="" uk-cover="" />
              ) : (
                <div className="uk-height-large">
                  <iframe
                    src={video}
                    className="uk-height-large"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    width="100%"
                  />
                </div>
              )}
            </div>
          ) : null}
          <div className="uk-width-2-5@m uk-flex uk-flex-middle">
            <div className="uk-card-body uk-padding">
              <h2>{title}</h2>
              <p>{desc}</p>
              {!!link ? (
                <Link
                  to={link}
                  className="uk-button uk-button-primary uk-border-pill"
                >
                  {btn}
                </Link>
              ) : null}
            </div>
          </div>
          {align === "left" ? (
            <div className="uk-card-media-right uk-flex-first uk-flex-last@m uk-width-3-5@m uk-height-large uk-cover-container uk-border-rounded-top-right uk-border-rounded-bottom-right">
              {image ? (
                <img src={image} alt="" uk-cover="" />
              ) : (
                <div className="uk-height-large">
                  <iframe
                    src={video}
                    className="uk-height-large"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    width="100%"
                  />
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default SplitCard
