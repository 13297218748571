import React from "react"
import { Link } from "gatsby"

class SplitPanel extends React.Component {
  render() {
    const title = this.props.title
    const desc = this.props.desc
    const link = this.props.link
    const btn = this.props.btn
    const image = this.props.image
    const mobileImage = this.props.mobileImage
    const color = this.props.color
    const align = this.props.align
    const fullWidth = this.props.fullWidth
    const margin = this.props.margin

    if (align === "right") {
      var alignClass =
        "uk-width-2-5@m uk-height-large uk-flex uk-flex-middle uk-margin-auto-left panel-right"
    } else {
      var alignClass =
        "uk-width-2-5@m uk-height-large uk-flex uk-flex-middle panel-left"
    }

    if (color === "dark") {
      var colorClass = "uk-dark"
    } else {
      var colorClass = "uk-light"
    }

    if (margin === true) {
      var marginClass = "uk-margin-large-bottom"
    } else {
      var marginClass = ""
    }

    if (fullWidth === true) {
      var containerClass = "uk-container uk-position-relative"
      var borderRadius = ""
    } else {
      var containerClass = "uk-width-1-1 uk-position-relative"
      var borderRadius = "uk-border-rounded"
    }

    return (
      <div
        className={
          "uk-background-primary uk-cover-container " +
          colorClass +
          " " +
          marginClass +
          " " +
          borderRadius
        }
      >
        {image ? <img src={image} alt={title} uk-cover="" /> : null}
        {mobileImage ? (
          <img
            src={mobileImage}
            className={mobileImage ? "uk-hidden@s" : null}
            alt={title}
            uk-cover=""
          />
        ) : null}

        <div className={containerClass}>
          <div className="uk-grid uk-width-1-1">
            <div className={alignClass}>
              <div className="uk-padding uk-margin-left">
                <h2>{title}</h2>
                <p className="uk-text-p-large">{desc}</p>
                {!!link ? (
                  <Link
                    to={link}
                    className="uk-button uk-button-default uk-border-pill"
                  >
                    {btn}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SplitPanel
